<template>
  <div>
    <div class="heading">
      <h3>加入我们</h3>
      <p class="title">期待与您一起携手 共创未来</p>
    </div>
    <div class="recruit">
      <h5>招聘岗位</h5>
      <div class="recruit_c">
        <div>
          <div class="screen">
            <h6>职位筛选</h6>
            <el-input
              placeholder="输入职位关键字"
              v-model="input3"
              class="input-with-select"
            >
              <el-button slot="append">搜索职位</el-button>
            </el-input>
            <div class="checkbox">
              <el-select class="check" v-model="value" placeholder="成都">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select class="check" v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select class="check" v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="position">
            <h4>酒店运营（成都）</h4>
            <p>
              1.熟悉企业日常管理运营；2.有良好的沟通能力；3.有相近似的岗位经验1-3年；
            </p>
            <p>
                <span>四川·成都市</span>
                <span>发布时间：2022-06-08</span>
            </p>
          </div>
        </div>
        <div class="new">
            <h6>最新职位</h6>
            <p><span>1</span>XX职位</p>
            <p><span>2</span>XX职位</p>
            <p><span>3</span>XX职位</p>
            <p><span>4</span>XX职位</p>
            <p><span>5</span>XX职位</p>
        </div>
      </div>
    </div>

    <div class="contact">
      <h5>联系我们</h5>
      <div class="contact_b">
        <div class="contact_c">
          <p class="contact_s">
            <span>地址</span>
            四川省成都市成都市武侯区天益街38号理想中心1栋1306
          </p>
          <div class="contact_l">
            <div class="contact_z">
              <img src="../../assets/images/phone.png" alt="" />
              <p>
                咨询电话：<br />
                028-6270-5855
              </p>
            </div>
            <div class="contact_z">
              <img src="../../assets/images/mailbox.png" alt="" />

              <p>
                邮箱：<br />
                hr@sindon.cn
              </p>
            </div>
            <div class="contact_a">
              <img src="../../assets/images/rw.png" alt="" />
              <p>关注我们</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input3: "",
      options: [
        {
          value: "选项1",
          label: "无",
        },
      ],
      value: "",
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/joinus.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  .title {
    width: 7.43rem;
    margin: 0.32rem auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
  .heading_c {
    position: absolute;
    bottom: -2rem;
    right: 2.4rem;
    display: flex;
    width: 14.4rem;
    height: 2.7rem;
    background-image: url(../../assets/images/us.png);
    background-size: 100% 100%;
    div {
      width: 8.72rem;
      height: 2.7rem;
      text-align: left;
      box-sizing: border-box;
      padding: 0.7rem 0.88rem;
      h4 {
        font-size: 0.28rem;
      }
      p {
        line-height: 0.3rem;
        font-size: 0.22rem;
      }
    }
  }
}
.recruit {
  h5 {
    padding: 1.2rem 0 0.58rem;
    text-align: center;
    font-size: 0.28rem;
  }
  .recruit_c {
    background-color: #fafafa;
    padding: 0.58rem 1.43rem 0.33rem;
    display: flex;
    justify-content: space-between;
    .screen {
      background-color: #fff;
      width: 12.1rem;
      height: 2.46rem;
      border-radius: 0.05rem;
      box-sizing: border-box;
      padding: 0.24rem 0.4rem;
      position: relative;
      h6 {
        font-size: 0.22rem;
      }
      .checkbox {
        position: absolute;
        bottom: 0.26rem;
        .check {
          margin-right: 0.2rem;
        }
      }
    }
    .position {
      background-color: #fff;
      width: 12.1rem;
      height: 1.64rem;
      margin-top: 0.14rem;
      border-radius: 0.05rem;
      box-sizing: border-box;
      padding: 0.24rem 0.36rem;
      h4{
          font-size: 0.22rem;
      }
      p{
          color: #666;
          font-size: 0.16rem;
          padding-top: 0.2rem;
      }
      p:nth-child(3){
          display: flex;
          justify-content: space-between;
          color: #999;
      }
    }
    .new{
        width: 3.9rem;
        height: 4.49rem;
    background-color: #fff;
    border-radius: 0.05rem;
    box-sizing: border-box;
    padding: 0.24rem 0.26rem;
    h6{
        font-size: 0.22rem;
    }
    p{
        margin-top: 0.4rem;
        font-size:0.18rem ;
        span{
            display: inline-block;
            width:0.28rem ;
            height: 0.28rem;
            background-color: #ffc53d;
            color: #fff;
            text-align: center;
            line-height: 0.28rem;
            margin-right: 0.1rem;
        }
    }
    }
  }
}
.contact {
  color: #333;
  padding-bottom: 1.04rem;

  h5 {
    padding: 1.2rem 0 0.41rem;
    text-align: center;
    font-size: 0.28rem;
  }
  .contact_b {
    background-color: #fafafa;
    padding: 0.62rem 0;
    .contact_c {
      width: 14.4rem;
      height: 3.49rem;
      margin: 0 auto;
      padding-bottom: 0.5rem;
      background-color: #fff;
      border-radius: 0.1rem;
      .contact_s {
        padding: 0.65rem 0.68rem;
        font-size: 0.18rem;
        span {
          display: block;
          font-size: 0.22rem;
          font-weight: 600;
          padding-bottom: 0.1rem;
        }
      }
      .contact_l {
        width: 14rem;
        margin: 0 auto;
        box-sizing: border-box;
        height: 1.75rem;
        background-color: #fafafa;
        border-radius: 0.1rem;
        display: flex;
        .contact_z {
          display: flex;
          padding: 0.47rem 2.3rem 0.47rem 0.48rem;
          img {
            width: 0.8rem;
            height: 0.8rem;
          }
          p {
            padding-left: 0.2rem;
            font-size: 0.22rem;
            line-height: 0.4rem;
          }
        }
        .contact_a {
          color: #333;
          padding: 0.1rem 1.2rem 0.47rem 0.48rem;
          text-align: center;
          img {
            width: 1.18rem;
            height: 1.18rem;
          }
          p {
            font-size: 0.18rem;
          }
        }
      }
    }
  }
}

.el-button {
  background-color: #ffc53d !important;
  color: #fff !important;
  border-radius: 0 0 0 0 !important ;
}
</style>
